/* Footer container styles */
.footer {
    background-color: #333;
    color: #fff;
    padding-top: 40px;
    padding-bottom: 40px;
    position: relative;
    bottom: 0;
    width: 100%;
  }
  
  .footer-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #f5a623;
  }
  
  .footer-description-rea {
    font-size: 1rem;
    color: #888;
    margin-bottom: 20px;
  }
  
  /* Social Media Icons */
  .social-icons .social-icon {
    font-size: 1.5rem;
    color: #fff;
    margin: 0 15px;
    transition: color 0.3s ease, transform 0.3s ease;
  }
  
  .social-icons .social-icon:hover {
    color: #f5a623;
    transform: scale(1.2);
  }
  
  /* Footer Links */
  .footer-links {
    margin-top: 20px;
  }
  
  .footer-link {
    color: #ddd;
    text-decoration: none;
    margin: 0 15px;
    font-size: 1rem;
    transition: color 0.3s ease;
  }
  
  .footer-link:hover {
    color: #f5a623;
  }
  
  /* Copyright Text */
  .footer-copyright {
    font-size: 0.9rem;
    color: #888;
    margin-top: 20px;
  }
  
  /* Responsive Layout */
  @media (max-width: 768px) {
    .footer-title {
      font-size: 1.25rem;
    }
    
    .footer-description {
      font-size: 0.9rem;
    }
    
    .footer-link {
      font-size: 0.9rem;
      margin: 0 10px;
    }
    
    .social-icons {
      margin-top: 20px;
    }
  }
  