
/* General Layout */
body {
  background-color: #f0f4f8; /* Light blue/gray color */
  /* You can choose any color you want */
}

.container {
  max-width: 800px;
  padding: 1rem;
  margin: 0 auto;
}

/* Center elements */
.text-center {
  text-align: center;
}

/* Margins and Padding */
.mb-4 {
  margin-bottom: 1rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* Typography */
h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #1d4ed8;
}

/* Input Field */
textarea,
input[type="file"],
input[type="range"] {
  background-color: #1f2937;
  color: #f9fafb;
  border: 1px solid #374151;
  border-radius: 0.375rem;
  width: 100%;
  padding: 0.75rem;
  resize: none;
}

textarea {
  min-height: 150px;
}

/* Button Styles */
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  font-weight: 600;
  border-radius: 0.375rem;
  transition: background-color 0.3s ease, border 0.3s ease;
  cursor: pointer;
}

/* Primary Button */
.button-primary {
  background-color: #3b82f6;
  color: #ffffff;
  margin: 10px;
}

.button-primary:hover {
  background-color: #2563eb;
}

/* Secondary Button */
.button-secondary {
  background-color: #4b5563;
  color: #ffffff;
}

.button-secondary:hover {
  background-color: #374151;
}

/* Success Button */
.button-success {
  background-color: #10b981;
  color: #ffffff;
  margin: 10px;
}

.button-success:hover {
  background-color: #059669;
}

/* Tooltip styling */
.tooltip-inner {
  background-color: #374151;
  color: #ffffff;
}

/* Last Copied Highlight */
.last-copied {
  border: 2px solid #0288d1; /* Blue border */
  transition: background-color 0.4s ease, border-color 0.4s ease;
  box-shadow: 0 4px 12px rgba(0, 120, 215, 0.4);
  border-radius: 8px;
}

.last-copied textarea {
  background-color: #2321cd;
  border-color: #0288d1;
}

.me-2 btn btn-primary {
  background-color: #0288d1;
}

/* Fancy Input Style */
.fancy-input-group {
  position: relative;
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #f9fafb;
  transition: transform 0.3s ease;
}

.fancy-input-group:hover {
  transform: translateY(-5px);
}

.fancy-input-group label {
  font-weight: bold;
  color: #374151;
  margin-bottom: 0.5rem;
  display: block;
}

.fancy-input-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 2px solid #cbd5e1;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.fancy-input-group textarea:focus {
  border-color: #3b82f6;
  box-shadow: 0 0 5px rgba(59, 130, 246, 0.5);
  outline: none;
}

.fancy-input-group textarea::placeholder {
  color: #9ca3af;
}

.logo-container {
  margin-bottom: 1rem; /* Adds spacing between the logo and the title */
}

.logo {
  max-width: 150px; /* Ensures the image doesn't exceed the specified size */
  height: auto; /* Maintains aspect ratio */
  display: block;
  margin: 0 auto; /* Centers the image */
}

/* faq component */
.faq-accordion .card {
  background-color: #1f2937; /* Dark gray background */
  border: none; /* Remove default border */
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.faq-accordion .card-header {
  cursor: pointer;
  background: none;
  transition: background-color 0.3s ease;
}

.faq-accordion .card-header:hover {
  background-color: #374151; /* Hover effect */
}

.faq-accordion .card-header .transform {
  transition: transform 0.3s ease;
}

.faq-accordion .card-header .transform.rotate {
  transform: rotate(45deg); /* Rotate icon */
}

.faq-accordion .fas {
  color: #9ca3af; /* Gray icon */
}

.d-flex {
  display: flex !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-md-row {
  flex-direction: row !important;
}

.gap-3 {
  gap: 1rem;
}

.w-100 {
  width: 100%;
}

.w-md-50 {
  width: 50% !important; /* Adjust for larger screens */
}

/* Style for btn class */
.btn {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Style for btn-Warning class */
.btn-Warning-reset {
  background-color: #f39c12; /* Yellow-orange background color */
  color: white;
  border: none;
  margin-left: 280px;
}

.btn-Warning-reset:hover {
  background-color: #e67e22; /* Darker orange on hover */
  transform: translateY(-2px); /* Slight lift effect */
}

.btn-Warning-reset:active {
  background-color: #d35400; /* Even darker on active click */
  transform: translateY(0); /* Reset lift effect */
}

/* Center the button horizontally and vertically */
.center-btn {
  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center;     /* Vertically center */
  height: 100vh;           /* Full viewport height for vertical centering */
}