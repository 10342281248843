/* Header.css */

.header-navbar {
  background-color: #007bff; /* Blue background */
  height: 60px; /* Set the height to 30px */
  padding-left: 1rem; /* Padding for left side */
  padding-right: 1rem; /* Padding for right side */
}

.header-container {
  display: flex;
  justify-content: center; /* Center align items horizontally */
  align-items: center; /* Center align items vertically */
}

.header-nav {
  display: flex;
  margin-right: auto; /* Push the navigation links to the left */
}

.header-link {
  color: white; /* White text color */
  margin: 0 0.5rem; /* Small margin between the links */
}

.header-link:hover {
  text-decoration: none; /* Remove underline on hover */
}

.header-icon {
  color: white; /* White color for social media icons */
  margin: 0 0.5rem; /* Small margin between the icons */
}

.header-icon:hover {
  color: #ddd; /* Slight color change on hover for icons */
}
