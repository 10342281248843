/* Container for the FAQ items - using CSS Grid to create a 2-column layout */
.faq-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);  /* 2 equal-width columns */
    gap: 20px;  /* Space between columns */
    margin-top: 20px;
  }
  
  /* Style for each FAQ item */
  .faq-column {
    display: flex;
    flex-direction: column;
    gap: 10px;  /* Space between individual FAQ items */
    transition: transform 0.3s ease-in-out;
  }
  
  /* Hover effect to scale up the FAQ item */
  .faq-column:hover {
    transform: scale(1.05);
  }
  
  /* Accordion Card Styling */
  .faq-card {
    background-color: #1a1a1a;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }
  
  /* On hover, add shadow and slight lift effect */
  .faq-card:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
    transform: translateY(-5px);
  }
  
  /* Accordion Header */
  .faq-header {
    background-color: #333;
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    color: #fff;
    font-weight: 600;
    font-size: 1.1rem;
    border-radius: 5px;
    transition: background-color 0.2s ease;
  }
  
  /* Hover effect for the header */
  .faq-header:hover {
    background-color: #444;
  }
  
  /* Accordion Body Styling */
  .faq-body {
    background-color: #282828;
    padding: 15px;
    font-size: 1rem;
    color: #ddd;
    border-bottom: 1px solid #444;
    border-top: 1px solid #444;
    transition: background-color 0.3s ease;
  }
  
  /* Accordion Collapse Fade Animation */
  .faq-accordion .fade-enter, .faq-accordion .fade-exit {
    opacity: 0;
  }
  
  .faq-accordion .fade-enter-active, .faq-accordion .fade-exit-active {
    opacity: 1;
    transition: opacity 300ms ease-in-out;
  }
  
  /* Rotate icon when the item is open */
  .transform.rotate {
    transform: rotate(45deg);
    transition: transform 0.3s ease-in-out;
  }
  
  /* Custom icon styling */
  .faq-header .fas {
    transition: transform 0.3s ease;
  }
  
  /* Fancy icon and transition */
  .faq-header .fas {
    color: #f5a623;
    font-size: 1.2rem;
  }
  
  /* Icon change on hover (rotate and color change) */
  .faq-header:hover .fas {
    transform: rotate(45deg);
    color: #f56a79;
  }
  
  /* Make sure to space out the FAQ content when collapsed */
  .faq-body {
    padding-top: 0;
    padding-bottom: 0;
  }
  
  .faq-body p {
    line-height: 1.6;
  }
  
  /* Add transition effect to transform */
  .faq-body {
    transition: max-height 0.4s ease-in-out;
    max-height: 0;
    overflow: hidden;
  }
  
  .faq-body.open {
    max-height: 500px;  /* Adjust according to your content size */
    border-radius: 5px;
  }
  
  /* Additional general styling */

  
  button {
    transition: all 0.3s ease;
    background-color: #333;
    color: #fff;
  }
  
  button:focus {
    outline: none;
  }
  
  /* Fancy border and spacing for the FAQ items */
  .faq-container {
    margin-top: 40px;
    padding: 20px;
  }
  